import React from 'react';

const ServiceStatusMessage = () => {

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6 text-center">
          
         
            <div>
              <p className="text-danger">Service is currently not available at your location.</p>
              <p className="text-info">We are working towards providing service to your location in the future.</p>
            </div>
        
        </div>
      </div>
    </div>
  );
};

export default ServiceStatusMessage;
