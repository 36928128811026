import { createSlice } from '@reduxjs/toolkit';

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    admin: false,
  },
  reducers: {
    adminLogin: (state, action) => {
      state.admin = action.payload;
    },
    adminLogout: (state,action) => {
      state.admin = action.payload;
    },
    setAdmin: (state, action) => {
      state.admin = action.payload;
    },
  },
});

export const { adminLogin, adminLogout, setAdmin } = adminSlice.actions;
export default adminSlice.reducer;
