import React,{useEffect,useState} from 'react'
import styled from  'styled-components';
export default function Banner() {
  const [contentIndex, setContentIndex] = useState(0);

  const headings = [
    'Our Commitment to Excellence',
    'Building Dreams, One Project at a Time',
    'Quality Materials, Expert Craftsmanship',
  ];
  
  const quotes = [
    'Dedicated to delivering excellence in every project, we set the bar high for quality and professionalism.',
    "From concept to completion, we're here to turn your vision into reality. Your dream project starts with us.",
    'With top-notch materials and skilled manpower, we ensure that every construction stands strong and stands out.',
  ];
  

  useEffect(() => {
    const interval = setInterval(() => {
      setContentIndex((prevIndex) => (prevIndex + 1) % headings.length);
    }, 7000); // Change heading and quote every 3 seconds

    return () => clearInterval(interval);
  }, []);


  return (
  
    <BannerLayout style={{height:'40vh',overflow:'visible'}} className='d-flex rounded shadow  py-4 justify-content-center  mt-5 mb-5 align-items-center'>

        <div className="ml-4 mb-4">
          <div className=" justify-content-center">
            <div className=" text-center">
              <div className="banner-content">
                <div
                  style={{
                    opacity: 0.8,
                    transition: 'opacity 1s ease-in-out',
                  }}
                >
                  <h1 className="font-weight-bold  py-4 text-with-shadow" style={{textDecoration:'underline'}}>{headings[contentIndex]}</h1>
                  <h2 className="font-italic py-2" style={{color:''}}>{quotes[contentIndex]}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

      </BannerLayout>
    
  
  
  )
}

const BannerLayout = styled.section`

width:100%;
color:white;
heigth:60vh; 

background:linear-gradient(to right,rgb(0 0 0 / 0.5),rgb(0 0 0 / 0)),url('/Assets/img4.jpeg');
background-position:center;
background-size:cover;
background-color: #f0f0f0;
background-

text-align:center;

@media screen and (max-width:786px){
  
  h1{
    font-size:30px !important;
  }
  h2{
    font-size:24px !important;
    margin-left:-24px;
  }
}
`