import React, { useEffect,useState } from 'react';
import { adminGetUser } from '../Api';
import { success,error } from '../Config/toastify';


const UserDetails = () => {
  const [orders, setOrders] = useState([]);
  // Sample data for orders (replace with actual data from your API or state)
  
  useEffect(() => {
    const fetchOrders = async () => {
        try {
            const response = await adminGetUser();
    
            if (response.success) {
              console.log(response.data.data);
              setOrders(response.data.data);
              success('All Orders are Here!') // Assuming your data is in the "data" property of the response
            } else {
              error(error.message);
            }
          } catch (error) {
         console.log(error.message);
          }
    };

    fetchOrders();
  }, []); // Empty dependency array ensures the effect runs only once on mount
  

  const handleContactButtonClick = (emailadd) => {
    // Replace with your email address
    const mailtoUrl = `mailto:${emailadd}`;
    window.location.href = mailtoUrl;
  };
  return (
    <>
    
        
    {orders.length === 0 ? (<h1 className='mt-4 text-center' style={{fontSize:'24px',fontWeight:'400'}}>Loading</h1>): (
    <div className="container mt-4">
      <h2>User</h2>
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <th>UserID</th>
            <th>UserName</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.order_id}>
              <td>{order.user_id}</td>
              <td>{order.username}</td>
              <td>{order.email}</td>
              <td>
               
                <button type="button" className="btn btn-danger btn-sm" onClick={() =>{handleContactButtonClick(order.email)}}>
                 Send Mail
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    )}
    </>
  );
};

export default UserDetails;
