import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getSubManServices } from '../Api';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addItem } from '../Redux/Reducers/orderSlice';
import Loader from './Loader';
import styled from 'styled-components';
export default function SubManpower() {
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const decryptId = window.atob(id);



    const listService =[
        'Labours',
        'AV System',
        'Lighting System',
        'IT SYSTEM',
        'Project Manager',
        'Project Assistant',
        'Fire Fighting & Alarm'
      ];
  
    useEffect(() => {

        const callGetServices = async () => {
          try {
            const response = await getSubManServices(decryptId);
            if (response.success) {
              setData(response.data.data);
    
            } else {
              console.log('Error fetching services');
            }
          } catch (error) {
            console.error('An error occurred:', error);
          }
        };
    
        callGetServices();
      }, [id]);
      const handlleBookNow = (orderItem) => {
        dispatch(addItem(orderItem));
        navigate('/services/Booking');
      }
  return (
    <>
    <div className='text-center py-2 mt-4 mb-4'>
        <h2>Services for <span className='px-2 text-primary'>{listService[decryptId - 1]}</span> </h2>
      </div>
      <hr />
      <Service className='container mt-2 mb-5'>
        <>

        </>
        {data.length ? (
          data.map((service, index) => (
            <div className='mt-5 hoverEffect' id={service.id} key={index} >
              <div className="card shadow" style={{ width: '18rem' }}>

                <div className="card-body">
                  <p className="card-text text-center titleWithFont">{service.title}</p>
                </div>
               
                <div className='card-body'>
                  <p className='card-text text-center'>visiting charges <span className='titleWithFont'> 55 SAR</span></p>
                </div>
                <div className='card-body d-flex justify-content-center'>
                  <button className='btn btn-dark rounded text-white titleWithFont' onClick={() => { handlleBookNow(service) }}>Book Now</button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <LoaderLayout>
          <Loader/>
          </LoaderLayout>
        )}
      </Service>
    </>
  )
}

const LoaderLayout = styled.section`
width:90vw;
height:70vh;
display:flex;
justify-content:center;
align-items:center;
`
const Service = styled.section`
width: 100vw;


 display:grid;
grid-template-columns: repeat(3, auto);
align-items:center;
justify-items:center;
@media screen and (max-width:786px){
    
  grid-template-columns: 1fr ;
 
  justify-items: center;
  
}
@media screen and (min-width: 790px) and (max-width: 1180px) {
   grid-template-columns: 1fr 1fr;
  
}

`

