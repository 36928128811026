import React from 'react'; 
import { useEffect, useState } from "react";
import {useSelector} from 'react-redux';
import LoginPage from "./AuthComponents/LoginPage";
import Navbar from "./Components/Navbar";
import { BrowserRouter, Routes, Route, Link ,Navigate} from 'react-router-dom';
import Signup from './AuthComponents/SignupPage'
import Home from "./Components/Home";
import ForgotPassword from "./AuthComponents/ForgotPassword";
import SignupPage from "./AuthComponents/SignupPage";
import PreviousOrders from "./Components/PreviousOrders";
import SmallNav from "./Components/SmallNav";
import SubServices from './Components/SubServices';
import Services from './Components/Services';
import Booking from "./Components/Booking";
import UserProfile from "./Components/UserProfile";
import LocationNav from './Components/LocationNav';
import NewPassword from './AuthComponents/NewPassword';
import Dashboard from './Admin/Dashboard';
import PartnerDashboard from './Admin/partnerDashboard';
import Orders from './Admin/Orders';
import ManPower from './Components/ManPower';
import SubManpower from './Components/SubManpower';
import UserDetails from './Admin/UserDetails';
import ServiceStatusMessage from './Components/ServiceStatusMessage';
import PartnerForm from './Components/partner/partnerForm';
import Partnerlogin from './Components/partner/partnerlogin';
import ParnterUser from './Admin/partnerUser';



function App() {
  const isServiceAvailable = useSelector((state) => state.isServiceAvailable);
  const isAdminRoute = window.location.pathname.startsWith('/admin');
  const[burgerStatus,setBurgerStatus]=useState(false);
  const auth = useSelector((state) => state.auth);
  const isAuthenticated =  useSelector((state) => state.adminauth.admin);
 useEffect(()=>{},[isAuthenticated]);
  const ProtectedOrder = () => {
  
    if (isAuthenticated) {
      return <Orders />;
    } else {
      return <Navigate to="/admin" />;
    }
  };

  

  const ProtectedUseradmin = () => {
    if (isAuthenticated) {
      return <UserDetails />;
    } else {
      return <Navigate to="/admin" />;
    }
  };



  const ProtectedBookNow = () => {
     if(isServiceAvailable.available){
      console.log('serivece status' )
    if (auth.user !== null) {
      return <Booking />;
    } else {
      return <Navigate to="/signin" />;
     }}else{
      return <Navigate to="/serviceNotAvailableAtYourLocation" />;
    }
  };
  
 
  // const ProtectedMSubServices = () => {
  //   if (auth.user !== null) {
  //     return <SubManpower />;
  //   } else {
  //     return <Navigate to="/signin" />;
  //   }
  // };
  return (
    <>
     <div className="smallNav">
     {!isAdminRoute? ( <LocationNav  burgerStatus={burgerStatus} setBurgerStatus={setBurgerStatus} />):('')}
    </div>
    {!isAdminRoute? ( <Navbar  burgerStatus={burgerStatus} setBurgerStatus={setBurgerStatus} />):('')}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<LoginPage />} />
        <Route path="/partnerForm" element={<PartnerForm />} />
        <Route path="/partnerlogin" element={<Partnerlogin />} />
        <Route path="/previousOrders"  element={<PreviousOrders />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/services/:id" element={<SubServices />} />
        <Route path="/services/manpower/:id" element={<SubManpower />} />
        <Route path="/services/booking" element={<ProtectedBookNow />} />
        <Route path="/userProfile"  element={<UserProfile />} />
        <Route path="/services" element={<Services />} />
        <Route path="/serviceNotAvailableAtYourLocation" element={<ServiceStatusMessage/>}/>
        <Route path="/:token/passwordReset" element={<NewPassword />} />
        <Route path='/admin' element={<Dashboard/>}/>
        <Route path='/admin/dashboard/User' element={<ProtectedUseradmin/>}/>
        <Route path='/admin/dashboard/partnerDashboard' element={<PartnerDashboard/>}/>

        <Route path='/admin/dashboard/parnterUser' element={<ParnterUser/>}/>
        <Route path='/manpowersupply' element={<ManPower/>}/>
        
        <Route path='/admin/dashboard/orders' element={<ProtectedOrder />}  />
       
  </Routes>
      <>
     </>
  
      <div style={{ height: '12vh' }} className="largeHidden"></div>
      <div className="smallNav">
      {!isAdminRoute? ( <SmallNav />):('')}
      </div>
    </>
  );
}

export default App;