
import { API_URLS } from '../Utils';




const customFetch = async (url, { body, ...customConfig }) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded', // Change to 'application/json' if sending JSON data
        ...customConfig.headers,
    };

    const config = {
        ...customConfig,
        headers: headers,
    };

    if (body) {


        //If sending form-urlencoded data
        const formData = new URLSearchParams();
        for (const key in body) {
            formData.append(key, body[key]);
        }
        config.body = formData.toString();
    }

    try {
        const response = await fetch(url, config);
        const data = await response.json();

        if (response.ok) {
            return {
                data: data,
                success: true,
            };
        }

        throw new Error(data.message || 'Request failed');
    } catch (error) {
        console.error(error.message);
        return {
            message: error.message,
            success: false,
        };
    }
};

// export const SignupPartner = async (formData) => {
//     try {
//         const response = await customFetch(API_URLS.SignUpPartner(), {
//             method: 'POST',
//             body: formData
//         });

//         if (response.success) {
//             console.log('Partner signed up successfully');
//             // You can handle further actions after successful signup if needed
//         } else {
//             console.error('Partner signup failed:', response.message);
//             // You can handle further error handling here
//         }
//     } catch (error) {
//         console.error('An error occurred during partner signup:', error);
//         // You can handle further error handling here
//     }
// };


export const createNewUserAccount = (authUser) => {
    return customFetch(API_URLS.Signup(), {
        method: 'POST',
        body: {
            username: authUser.name,
            email: authUser.email,
            password_hash: authUser.password,
            confirm_password: authUser.confirmpassword,
            phone:authUser.phone

        },
    });
};
export const createSessionWithEmail = (authUser) => {
    return customFetch(API_URLS.Signin(), {
        method: 'POST',
        body: {

            identifier: authUser.identifier,
            password_hash: authUser.password,

        },
    });
};

export const createSessionWithMobile = (authUser) => {
    return customFetch(API_URLS.SigninM(), {
        method: 'POST',
        body: {

            identifier: authUser.identifier,
            password_hash: authUser.password,

        },
    });
};

//services
export const getServices = () => {
    return customFetch(API_URLS.services(), {
        method: 'get'
    });
};

//Subservices
export const getSubServices = (id) => {
    return customFetch(API_URLS.subServices(id), {
        method: 'get'
    });
};
export const getSubManServices = (id) => {
    return customFetch(API_URLS.subManServices(id), {
        method: 'get'
    });
};

//orders
export const getOrders = (id) => {
    return customFetch(API_URLS.getOrdersOfUser(), {
        method: 'post',
        body: {
            id: id
        }
    }
    );
};

//placeOrders
export const newOrder = (order) => {
    return customFetch(API_URLS.placeOrder(), {
        method: 'post',
        body: {
            user_id: order.userId, order_delivery_address: order.address, payment_type: order.payment, ordered_item: order.item, order_cost: order.cost, phone_number: order.phone
        }
    }
    );
};

//reset pssword

export const resetByRequestForPassword = (user) => {
    return customFetch(API_URLS.resetPasswd(), {
        method: 'post',
        body: {
            email: user.email
        }
    }
    );
};

export const resetByRequestForNewPassword = (user) => {
    return customFetch(API_URLS.resetNewPasswd(), {
        method: 'post',
        body: {
            token:user.token, newPassword:user.newpassword
        }
    }
    );
};


export const adminAuthentication = (Adminpassword) => {
    return customFetch(API_URLS.adminLogin(), {
        method: 'post',
        body: {
           password:Adminpassword
        }
    }
    );
};

export const adminGetOrders = () => {
    return customFetch(API_URLS.getAllOrders(), {
        method: 'get'
    }
    );
};
export const adminGetUser = () => {
    return customFetch(API_URLS.getAllUsers(), {
        method: 'get'
    }
    );
};
export const adminDeletOrder = (order_id) => {
    return customFetch(API_URLS.deleteOrder(), {
        method: 'post',
        body:{
            orderId:order_id
        }
    }
    );
};
export const Getmanpower = () => {
    return customFetch(API_URLS.Manservices(), {
        method: 'get'
       
    }
    );
};


export const GetServiceStatus = (coordinate) => {
    return customFetch(API_URLS.checkServiceAvailable(coordinate), {
        method: 'post',body:{
         latitude:coordinate.latitude,
         longitude:coordinate.longitude
        }
       
    }
    );
};


export const GetAllPartners = () => {
    return customFetch(API_URLS.AllPartners(), {
        method: 'get'
    });
};