import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
export default function LocationNav({ burgerStatus, setBurgerStatus }) {
  const userLocation = useSelector((state => state.auth.location));

  return (
    <LocLayout className='rounded shadow' >
      <div className='px-2 py-2'>
        <LocationOnIcon  />
     
      <input
        type="text"
        placeholder="Your Location"
        className='rounded'
        value={userLocation}
        style={{ padding: '5px', marginRight: '10px' }}
      /> </div>
     
    </LocLayout>
  );
}


const LocLayout = styled.div`
border:2px solid silver;
display:flex;
justify-content:space-between;
`

