import React, { useState } from 'react';
import styled from 'styled-components';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { adminLogin, setAdmin } from '../Redux/Reducers/adminSlice';
import { error, success, warning } from '../Config/toastify';
import { adminAuthentication } from '../Api';

const AdminLoginCard = () => {
  const [adpassword, setadPassword] = useState('');
  const dispatch = useDispatch();


  const handleLoginClick = async () => {
    try {
      if (adpassword === '') {
        warning('Hello Admin, please enter your password');
        return;
      }

      const response = await adminAuthentication(adpassword);
      if(response.success){
          success('login successfull');
          dispatch(adminLogin(true));
      }
       else{
        setadPassword('');
        error('login failed,Contact Developer');
       }
     
      
    } catch (error) {
      console.error('Error during login:', error);
      error('An unexpected error occurred. Contact developer!');
    }
  };

  const handleRequestPassword = () => {
    // Implement password request logic here
    console.log('Request Password clicked');
    success('Admin, please check your Inbox');
  };

 

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Admin Login</h2>
          <form>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
            className="form-control"
            id="password"
            type="text"
            placeholder="Password"
            value={adpassword}
            onChange={(e) => setadPassword(e.target.value)}
          />
            </div>

            <div className="mb-3">
              <button type="button" className="btn btn-primary" onClick={() => {handleLoginClick()}}>
                Login
              </button>
            </div>

            
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginCard;
