import React from 'react';
import {useSelector} from 'react-redux';
function UserProfile() {
  const auth = useSelector((state) => state.auth);
  const userData = {
    name: auth.user.name,
    email: auth.user.email,
    previousOrders: [
      { id: 1, product: 'Product A', quantity: 2 },
      { id: 2, product: 'Product B', quantity: 1 },
      // Add more orders as needed
    ],
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-header">
          <h2>User Profile</h2>
        </div>
        <div className="card-body">
          <div className="mb-3">
            <h4>Name:</h4>
            <p>{userData.name}</p>
          </div>

          <div className="mb-3">
            <h4>Email:</h4>
            <p>{userData.email}</p>
          </div>

        


         
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
