import React, { useEffect } from 'react'
import Banner from './Banner'
import About from './About';
import Services from './Services';
import Footer from './Footer';
import MostLiked from './MostLiked';
import PartnerSection from './partnerSection/partnerSection';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
export default function Home() {
    useEffect(() => {
        document.title = 'Welcome  Home';
      }, []);
      const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the maximum width for mobile

    return (
        <>
 {isMobile && (
                <Link to="/partnerForm" className="btn btn-outline bg-dark text-light mx-2" style={{ position: 'absolute', right:'10px', top:'15px', zIndex: '1000', fontSize:'9px' }}>Join as a Partner</Link>
            )}
            <Services />
            <Banner />
            <MostLiked />
            <About />
            <PartnerSection />
            <Footer />
        </>
    )
}
