import React, { isValidElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { error, success, warning } from '../Config/toastify';
import { useEffect } from 'react';
import {createNewUserAccount} from '../Api';
import { isValidPhoneNumber, validateEmail } from '../Config/validateEmail';
function SignupPage() {
  const navigate = useNavigate();
  const [name,setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('966');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    document.title = ' Signup';
  }, []);



  const handleSignup = async (e) => {
    e.preventDefault();
   
    const userCredential ={
      name:name,
      email:email,
      phone:phoneNumber,
      password:window.btoa(password),
      confirmpassword:window.btoa(confirmPassword)

    }
    try{
    if(email === '' || password === '' || confirmPassword === ''){
      warning("All Fields are Required!");
      return;
      
    }
    if(!validateEmail(email)){
      error("InValid Email Address");
      return;
    }
    if(!isValidPhoneNumber(phoneNumber)){
error('InValid Phone Number');
return;
    }
    if(password !== confirmPassword){
      error("password mismatch");
      return;
    }
    else if(password.length < 8){
      error("password atleast contains 8 characters");
      return;
    }
    
    const response = await createNewUserAccount( userCredential ); // Corrected parameter

    if (response.success) {
    
    success('Account created Successfully!');
    navigate('/Signin');
    } else {
      error('Something went wrong please try again')
    }}
    catch(error){
      console.log(error.message);
    }
  
   
  };

  return (
    <div className="container mt-5">
      <p className='fw-bold text-center text-with-shadow'>Welcome To liyanGulf</p>
      <form className="card p-4 mx-auto" onSubmit={handleSignup}>
      <div className="mb-3 form-group">
          <label className="form-label" htmlFor="username">
            Name
          </label>
          <input
            className="form-control"
            id="username"
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3  form-group">
          <label className="form-label" htmlFor="mobileNumber">
            Mobile Number
          </label>
          <input
            className="form-control"
            id="mobileNumber"
            type="text"
            placeholder="Enter your number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div className="mb-3  form-group">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input
            className="form-control"
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-3 form-group">
          <label className="form-label" htmlFor="password">
            Password
          </label>
          <input
            className="form-control"
            id="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-3  form-group">
          <label className="form-label" htmlFor="confirmPassword">
            Confirm Password
          </label>
          <input
            className="form-control"
            id="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <div className="d-grid">
          <button
            className="btn btn-primary"
            type="submit"
          >
            Create Account
          </button>
        </div>
      </form>
    </div>
  );
}

export default SignupPage;
