import { useState,useEffect } from "react";
import { resetByRequestForNewPassword } from "../Api";
import { error, success, warning } from "../Config/toastify";
import { useNavigate,useParams } from "react-router-dom";
export default function NewPassword() {
  const [NewPassword,setNewPassword] = useState('');
  const [confirmPassd,setConfirmPasd] = useState('');
  const navigate = useNavigate();
  
  const { token } = useParams();
  useEffect(() => {
    document.title = 'Password Reset page';
  }, []);
const handleNewPasswordRequest = async (e)=>{
  e.preventDefault();
  if(NewPassword === '' || confirmPassd === ''){
    warning('password not set fill the box!');
    return;
  }
  if(NewPassword.length < 8){
    error("password atleast contian 8 characters");
    return;
  }
  if(NewPassword !== confirmPassd){
    error('password mis-match');
    return;
  }
  const userToken = {
    token : token,
    newpassword : NewPassword
  }
  try{
 const response = await resetByRequestForNewPassword(userToken);
 if(response.success){
  success('password Updated successfully');
  navigate('/signin');
 }
 else{
  error('somethin went wrong');
  setNewPassword('');
  setConfirmPasd('');
  return;
 }
  }
  catch(err){
    console.log(error.message);
  }
 
}
  return (
    <div className="container mt-5">
      <form className="card p-4 mx-auto"  onSubmit={handleNewPasswordRequest}>
        <div className="mb-3">
          <label className="form-label" htmlFor="username">
            Set New Password
          </label>
          <input
            className="form-control"
            id="username"
            type="text"
            placeholder="new Password"
            value={NewPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="username">
            Set Confirm Password
          </label>
          <input
            className="form-control"
            id="username"
            type="text"
            placeholder="confirm password"
            value={confirmPassd}
            onChange={(e) => setConfirmPasd(e.target.value)}
            required
          />
        </div>

        <div className="d-grid">
          <button
            className="btn btn-primary"
            type="submit"
       
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
