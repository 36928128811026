import { createSlice } from "@reduxjs/toolkit";

const serviceAvailableSlice = createSlice({
  name: 'serviceAvailable',
  initialState: {
    available: false,
  },
  reducers: {
    checkServiceAvailabilitySuccess: (state,action) => {
      state.available = action.payload;
    }
  },
});

export const {
  checkServiceAvailabilitySuccess,
 
} = serviceAvailableSlice.actions;

export const selectServiceAvailability = (state) => state.serviceAvailable.available;

export default serviceAvailableSlice.reducer;
