import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useNavigate } from 'react-router-dom';
import AdminLoginCard from './AdminLoginCard';
import LogoutIcon from '@mui/icons-material/Logout';
import { adminLogout } from '../Redux/Reducers/adminSlice';

const StyledDashboard = styled.div`
  padding: 20px;
`;
const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Dashboard = () => {
  const navigate = useNavigate();
  const adminLoggedIn = useSelector((state) => state.adminauth.admin);
  const dispatch = useDispatch();

  const handleOrders = (typeRoute) => {
    if (typeRoute === 'Ord') {
      navigate('/admin/dashboard/orders');
    } else if (typeRoute === 'usr') {
      navigate('/admin/dashboard/User');
    } else if (typeRoute === 'partnerusr') {
      navigate('/admin/dashboard/parnterUser');
    }
  };

  const handleAdminLogout = () => {
    dispatch(adminLogout(false));
  };

  useEffect(() => {}, [adminLoggedIn]);

  return (
    <StyledDashboard>
      <div className="container">
        <h1>
          <AdminPanelSettingsIcon fontSize="large" color="primary" /> Admin Console <LogoutIcon onClick={() => { handleAdminLogout(); }} />
        </h1>
        {!adminLoggedIn && <AdminLoginCard />}
        {adminLoggedIn && (
          <div className="row" style={{ cursor: 'pointer' }}>
            <div className="col-md-4">
              <Card
                onClick={() => {
                  handleOrders('Ord');
                }}
              >
                <h3>Orders</h3>
                <p>View and manage orders</p>
              </Card>
            </div>
            <div className="col-md-4">
              <Card
                onClick={() => {
                  handleOrders('usr');
                }}
              >
                <h3>User</h3>
                <p>View and manage Users</p>
              </Card>
            </div>
            <div className="col-md-4">
              <Card
                onClick={() => {
                  handleOrders('partnerusr');
                }}
              >
                <h3>Partner User</h3>
                <p>View and Manage Partner User </p>
              </Card>
            </div>
          </div>
        )}
      </div>
    </StyledDashboard>
  );
};

export default Dashboard;
