import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { partner_Url } from '../Utils';

const OrdersByCategory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { category } = location.state;
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Retrieve email from localStorage
  const email = localStorage.getItem('loggedInUserEmail');

  const handleLogout = () => {
    // Clear email from localStorage
    localStorage.removeItem('loggedInUserEmail');
    // Redirect to login page
    navigate('/partnerlogin');
  };

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${partner_Url}/admin/getorderByCategory/${email}/${category}`);

      if (response.status === 200) {
        if (response.data?.message) {
          setError(response.data.message);
        } else if (response.data?.data) {
          setOrders(response.data.data);
        }
      } else {
        setError('You are not approved to view orders');
      }
    } catch (error) {
      console.error('An error occurred while fetching orders:', error);
      setError('You are not approved to view orders');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!email) {
      // Redirect to login page if email is not found
      navigate('/partnerlogin');
    } else {
      fetchOrders();
    }
  }, [email, category, navigate]);

  const handleAcceptOrder = async (user_id, order_id) => {
    try {
      const response = await axios.post(`${partner_Url}/admin/forAcepted`, {
        acceptedBy: email,
        user_id,
        order_id
      });
      if (response.status === 200) {
        // If successful, update order status locally
        setOrders(prevOrders =>
          prevOrders.map(order =>
            order.order_id === order_id ? { ...order, order_status: 'accepted', acceptedBy: email } : order
          )
        );
      } else {
        console.error('Failed to accept order:', response.data?.message);
      }
    } catch (error) {
      console.error('An error occurred while accepting order:', error);
    }
  };

  const handleDoneOrder = async (order_id, user_id) => {
    try {
      const response = await axios.post(`${partner_Url}/admin/orderCompleted`, {
        acceptedBy: email,
        user_id,
        order_id
      });
      if (response.status === 200) {
        // If successful, update order status locally
        setOrders(prevOrders =>
          prevOrders.map(order =>
            order.order_id === order_id ? { ...order, order_status: 'completed' } : order
          )
        );
      } else {
        console.error('Failed to mark order as done:', response.data?.message);
      }
    } catch (error) {
      console.error('An error occurred while marking order as done:', error);
    }
  };

  return (
    <div className='container'>
      <button onClick={handleLogout} className="btn btn-danger">Logout</button>
      <h2>Orders by Category: {category}</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : orders?.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>User ID</th>
              <th>Delivery Address</th>
              <th>Service Required</th>
              <th>Order Date</th>
              <th>Phone Number</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {orders.map(order => (
              <tr key={order.order_id}>
                <td>{order.order_id}</td>
                <td>{order.user_id}</td>
                <td>{order.order_delivery_address}</td>
                <td>{order.service_required}</td>
                <td>{order.order_date}</td>
                <td>{order.phone_number}</td>
                <td>{order.order_status}</td>
                <td>
                  {order.order_status === 'pending' && (
                    <button className='btn btn-primary' onClick={() => handleAcceptOrder(order.user_id, order.order_id)}>Accept</button>
                  )}
                  {order.order_status === 'accepted' && email === order.acceptedBy && (
                    <button className='btn btn-success' onClick={() => handleDoneOrder(order.order_id, order.user_id)}>Done</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No orders available for the category: {category}</p>
      )}
    </div>
  );
};

export default OrdersByCategory;
