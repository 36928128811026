
export const ROOT_URL = 'https://liyangulfcontracting.com/myapp';
// export const ROOT_URL = 'http://localhost:7007/myapp';
export const partner_Url='https://liyangulfcontracting.com/myapp';
// export const partner_Url='http://localhost:7007/myapp';
export const API_URLS = {
// SignUpPartner:()=>`${ROOT_URL}/admin/subAdminSignUpAdmin`,
Signup : () => `${ROOT_URL}/auth/newAuthConnection`,
Signin: () => `${ROOT_URL}/auth/authCreateSession`,
SigninM: () => `${ROOT_URL}/auth/authCreateSession`,
logout: () => `${ROOT_URL}/auth/authCreateSession`, 
resetPasswd: () => `${ROOT_URL}/auth/resetPasswordRequest`,
resetNewPasswd: () => `${ROOT_URL}/auth/resetNewPasswordRequest`,
services: () => `${ROOT_URL}/services/getServiceCategory`,
Manservices: () => `${ROOT_URL}/services/getManpower`,
subServices:(id) =>`${ROOT_URL}/services/subServices/${id}`,
subManServices:(id) =>`${ROOT_URL}/services/getSubmanServices/${id}`,
getOrdersOfUser:() =>`${ROOT_URL}/orders`,
placeOrder:() =>`${ROOT_URL}/place-order`,
getAllOrders:() =>`${ROOT_URL}/getAllOrders`,
getAllUsers:() =>`${ROOT_URL}/getAllUser`,
deleteOrder:() =>`${ROOT_URL}/deleteOrder`,
adminLogin: () =>`${ROOT_URL}/admin/authCheckIsAdmin`,
checkServiceAvailable: () =>`${ROOT_URL}/checkServicesAvailability`,
getAllPartners: () => `${ROOT_URL}/admin/getAllPartners`

}  
