import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: 'Orders',
  initialState: {
    orders: null, // Use null instead of an empty array
  },
  reducers: {
    addItem: (state, action) => {
      // Add an item to the cart
      let Service = {
        order: action.payload,
      };
      state.orders = Service; // Replace the entire array with the new object
    },
  },
});

export const { addItem } = orderSlice.actions;
export default orderSlice.reducer;
