import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { success,error } from "../Config/toastify";
import { useNavigate } from "react-router-dom";
import {newOrder} from '../Api/index'
import {ROOT_URL} from '../Utils/index';
import { useSelector } from 'react-redux';

// Renders errors or successfull transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}

function PaymentComponent({Orders,delivery,phoneNumber}) {
  const initialOptions = {
    "client-id": "ATR8NYedHK1DT9YqWQdFg9heUgg_UbNX9pkR5rzfdBP-eZP866hJ6B5i7nDu-AmCbNYcFqII0aNSXV7D",
    "enable-funding": "card",
    "disable-funding": "",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };
  useEffect(()=>{
    console.log("add:",delivery,"ph:",phoneNumber);
  },[])
  const [message, setMessage] = useState("");
  let Navigate = useNavigate();
  const auth = useSelector((state) => state.auth)
  


  const handleConfirmOrder = async (data) => {
    try {
      const response = await newOrder(data);

      if (response.success) {
        success('Order successful');
        Navigate('/previousOrders');
      } else {
        error('Something went wrong. Please try again');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div style={{ maxWidth: "750px", minHeight: "200px" }}>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={async () => {
           
            
            try {
              const response = await fetch(`${ROOT_URL}/secure/api/orders`, {
                method: "POST",
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                // use the "body" param to optionally pass additional order information
                // like product ids and quantities
                body: JSON.stringify({
                  cart: [
                    {
                      id: "-",
                      quantity: "-",
                    },
                  ],
                }),
              });

              const orderData = await response.json();

              if (orderData.id) {
                return orderData.id;
              } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                  ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                  : JSON.stringify(orderData);

                throw new Error(errorMessage);
              }
            } catch (error) {
              console.error(error);
              setMessage(`Could not initiate PayPal Checkout...${error}`);
            }
        
        }}
          onApprove={async (data, actions) => {
           
            try {
              const response = await fetch(
                `${ROOT_URL}/secure/api/orders/${data.orderID}/capture`,
                {
                  method: "POST",
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                },
              );

              const orderData = await response.json();
              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you message

              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
              } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`,
                );
              } else {
                // (3) Successful transaction -> Show confirmation or thank you message
                // Or go to another URL:  actions.redirect('thank_you.html');
                const transaction =
                  orderData.purchase_units[0].payments.captures[0];
                setMessage(
                  `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
                );


                const OrderDetails = {
                  userId: auth.user.id,
                  address: delivery,
                  payment: transaction.id,
                  item: Orders.order.title,
                  cost: Orders.order.price,
                  phone: phoneNumber,
                };
                handleConfirmOrder(OrderDetails);
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2),
                );
              }
            } catch (error) {
              console.error(error);
              setMessage(
                `Sorry, your transaction could not be processed...${error}`,
              );
            }
          }}
        />
      </PayPalScriptProvider>
      <Message content={message} />
    </div>
  );
}

export default PaymentComponent;
