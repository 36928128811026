import React,{useState,useEffect}from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import { Getmanpower, manpower } from '../Api';
import Loader from './Loader';
export default function ManPower() {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
useEffect(() => {
  const callGetServices = async () => {
  try {
  const response = await Getmanpower();
  if (response.success) {
  setData(response.data.data);
  } else {
  console.log('Error fetching services');
  }
  } catch (error) {
  console.error('An error occurred:', error);
  }
  };
  
  callGetServices();
  }, []);
  const handleOnClick = (id) =>{
   let encryptedId = window.btoa(id);
    navigate(`/services/manpower/${encryptedId}`);
}
  return (
    <MapnPowerServiceLayout>
    <div className='rounded px-2 serviceBox shadow'>
   
   {data.length ? (
data.map((service, index) => (
<div className='mt-5 hoverEffect' id={service.id} key={index}  onClick={() =>{handleOnClick(service.id)}}>
<div className="card shadow  checkImageCenter" style={{ width: '8rem' }}>
 <div className='mt-3'>
<img width="48" height="48"  className=" imageFormard " src={service.image} alt="Card image cap" />
</div>
<div className="card-body">
<p className="card-text text-center titleWithFont">{service.title}</p>
</div>
</div>
</div>
))
) : (
<LoaderLayout>
  <Loader/>
</LoaderLayout>
)}
  
   
   </div>
   </MapnPowerServiceLayout>
  )
}
const LoaderLayout = styled.section`
width:90vw;
height:70vh;
display:flex;
justify-content:center;
align-items:center;
`
const  MapnPowerServiceLayout = styled.section`

.serviceBox{ display:grid;
  grid-template-columns: repeat(3, auto);
  align-items:center;
  justify-items:center;
  }
  .checkImageCenter {
    display:flex;
    flex-direction:column;
    align-items:center;
    }
overflow:hidden;
@media screen and (max-width: 780px) {
  .serviceLayout{
  display:flex;
  flex-direction:column;
  overflow:scroll;
}
.serviceBox{
  width:100%
 
}
.serviceBox{
  display:grid;
  grid-template-columns: repeat(2, auto);
}
}

`

