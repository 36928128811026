import React from 'react'
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'
import { Link } from 'react-router-dom';

function MostLiked() {
  let settings ={
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1, // fix the typo here
    autoplay: true,
  };
  let imageContainer = [{title:'A ceiling with thoughtful design turns any building into a masterpiece, adding charm and beauty to the spaces we live and work in',
  price:'SAR 55',
service:'Ceiling'},{
    title:'Like a vigilant guardian, a fire alarm system stands ready, its silent watch transformed into a life-saving symphony at the first whisper of danger.',
    price:'SAR 55',service:'Fire Safety'
  },
{
  title:'CCTV: Silent guardians, turning watchful eyes into a security symphony, protecting spaces with unwavering vigilance.',
  price:'SAR 55',service:'Security Camera'
}]
  return (
    
   <MostlikedLayout className='py-2 mt-5 mb-5'>
    <div className='text-center  '>
        <h2 className='text-with-shadow'>Most Ordered Services</h2>
      </div>
  <Carousel {...settings}  className='mt-5'>
    
       <Wrap className='wrap1  rounded shadow'>
         <div className='insideWraper'>
          <div>
            <p className='titleForOr'>{imageContainer[0].title}</p>
          </div>
          
          <div>
            <p className='price'>{imageContainer[0].price}</p>
          </div>
          <div>
          <Link to='/services/MQ=='>  <button className='btn rounded btn-dark text-white'>Book Now</button></Link>
         </div>
         </div>
         
       </Wrap>
       <Wrap className='wrap2  rounded shadow'>
         <div  className='insideWraper'>
          <div >
            <p className='titleForOr'>{imageContainer[1].title}</p>
          </div>
          
          <div>
            <p className='price'>{imageContainer[1].price}</p>
          </div>
          
          <div>
        <Link to="/services/Mw==">  <button className='btn rounded btn-dark text-white'>Book Now</button></Link>
         </div>
         </div>
        
       </Wrap>
       <Wrap className='wrap3 rounded shadow'>
         <div className='insideWraper'>
          <div >
            <p className='titleForOr'>{imageContainer[2].title}</p>
          </div>
          <div>
            <p className='price'>{imageContainer[2].price}</p>
          </div>
          <div>
        <Link to='/services/Mg=='  ><button className='btn rounded btn-dark text-white'>Book Now</button></Link>
         </div>
         </div>
        
       </Wrap>
   
    

  </Carousel>
  </MostlikedLayout>
  
  )
}

export default MostLiked;
const MostlikedLayout = styled.section`

width:90vw;
margin:auto;

`

const Carousel=styled(Slider)`



ul li button{
  &:before{
    font-size:10px;
    color:rgb(150,158,171);
  }
}
li.slick-active button::before{
  color:white;
}
.slick-list{
  overflow:hidden;
}
button{
 z-index:1;
}
.wrap1 {
  height:50vh;
  background:linear-gradient(to right,rgb(0 0 0 / 0.5),rgb(0 0 0 / 0)), url('https://images.unsplash.com/photo-1667893310241-08bf719d6fce?q=80&w=1332&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position:center;
  background-size:cover;
  background-color: #f0f0f0;
 
 
}

.wrap2{
  height:50vh;
  background:linear-gradient(to right,rgb(0 0 0 / 0.5),rgb(0 0 0 / 0)),url('https://images.unsplash.com/photo-1665655034446-1536f6de3fe6?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position:center;
  background-size:cover;
  background-color: #f0f0f0;
}
.wrap3{
  height:50vh;
  background:linear-gradient(to right,rgb(0 0 0 / 0.5),rgb(0 0 0 / 0)),url('https://images.unsplash.com/photo-1603559062568-c1f32a56087d?q=80&w=1475&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-position:center;
  background-size:cover;
  background-color: #f0f0f0;
}
`
const Wrap=styled.div`

.insideWraper{
  font-family: 'Montserrat', sans-serif;
  display:flex;
  flex-direction:column;
  margin-top:4px;
  margin-left:10px;
}

.titleForOr{
  font-size:40px;
  font-weight:900;
  color:silver;
  text-shadow:0px 0px 2px #000000;
}
.price{
  font-size:24px;
  font-weight:700;
  color:silver;
  letter-spacing:2px;
}

`