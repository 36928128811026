import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { createSessionWithMobile,createSessionWithEmail } from '../Api';
import { login ,setLocation} from "../Redux/Reducers/authSlice";
import {success,error,warning} from '../Config/toastify';
import { useDispatch,useSelector } from "react-redux";
import { isValidPhoneNumber,validateEmail } from "../Config/validateEmail";

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    document.title = 'Login page';
  }, []);

  

 

  const identifyInputType = (value) => {
    if (validateEmail(value)) {
      return 'email';
    } else if (isValidPhoneNumber(value)) {
      return 'phone';
    } else {
      return 'unknown';
    }
  };

  const handleSignin = async (e) => {
    e.preventDefault();

    try {
      if (identifier === '' || password === '') {
        warning('All Fields are Required!');
        return;
      }

      const inputType = identifyInputType(identifier);

      if (inputType === 'unknown') {
        warning('Invalid Email/Phone Number format');
        return;
      }

      const userCredential = {
        identifier: identifier,
        password: window.btoa(password),
        // Add the input type to the user credentials
      };

      let response;
      if (inputType === 'email') {
        response = await createSessionWithEmail(userCredential);
      } else if (inputType === 'phone') {
        response = await createSessionWithMobile(userCredential);
      }

      if (response.success) {
        dispatch(login(response.data.user));
        success('Authentication Successfully!');
        navigate('/');
      } else {
        error('Email/Password mismatch. Please try again.');
      }
    } catch (error) {
      console.log(error.message);
    }
  };


  return (
    <div className="container mt-5">
      <form className="card p-4 mx-auto" onSubmit={handleSignin}>
        <div className="mb-3">
          <label className="form-label" htmlFor="identifier">
            Email/Phone Number
          </label>
          <input
            className="form-control"
            id="identifier"
            type="text"
            placeholder="Enter Email or Phone Number"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="password">
            Password
          </label>
          <input
            className="form-control"
            id="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="d-grid">
          <button className="btn btn-primary" type="submit">
            Sign In
          </button>
        </div>
      </form>
      <p className="text-center mt-3">
        <Link to="/signup" className="text-primary">
          #Create New Account. Click here
        </Link>
      </p>
      <p className="text-center mt-3">
        <Link to="/forgotPassword" className="text-primary">
          #Forgot password? Click here
        </Link>
      </p>
    </div>
  );
}
