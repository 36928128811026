import React from 'react'
import { Link } from 'react-router-dom';
import RedeemIcon from '@mui/icons-material/Redeem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import HomeIcon from '@mui/icons-material/Home';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
export default function SmallNav() {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const handleAccountRoute = () => {
    // Check if currentUser.user exists and navigate accordingly
    if (auth.user) {
      navigate('/userProfile');
    } else {
      navigate('/signin');
    }
  };
  const handleOrderRoute = () => {
    // Check if currentUser.user exists and navigate accordingly
    if (auth.user) {
      navigate('/previousOrders');
    } else {
      navigate('/signin');
    }
  };
  return (
    <div className='container-fluid d-flex justify-content-around mt-4 rounded  bg-dark text-white fixed-bottom' style={{ border: '1px', borderStyle: 'solid' }}>
      <div className='d-flex flex-column align-items-center py-2'>
        <div>
          <Link to='/' style={{ textDecoration: 'none', color: 'white' }}> <HomeIcon /></Link>
        </div>
        <div>
          <p className='titleWithFont' style={{ textDecoration: 'none', color: 'gray' }}>Home</p>
        </div>
      </div>
      <div className='d-flex flex-column align-items-center py-2'>
        <div>
          <Link to='/services' style={{ textDecoration: 'none', color: 'white' }}> <MiscellaneousServicesIcon /></Link>
        </div>
        <div>
          <p className='titleWithFont' style={{ textDecoration: 'none', color: 'gray' }}>Service</p>
        </div>
      </div>
      <div className='d-flex flex-column align-items-center py-2'>
        <div>
          <a style={{ textDecoration: 'none', color: 'white' }} onClick={() => { handleOrderRoute() }}> <RedeemIcon /></a>
        </div>
        <div>
          <p className='titleWithFont' style={{ textDecoration: 'none', color: 'gray' }}>Orders</p>
        </div>
      </div>

      <div className='d-flex flex-column align-items-center py-2'>
        <div>
          <a style={{ textDecoration: 'none', color: 'white' }} onClick={() => { handleAccountRoute() }}> <AccountCircleIcon /></a>
        </div>
        <div >
          <p className='titleWithFont' style={{ textDecoration: 'none', color: 'gray' }}>Account</p>
        </div>
      </div>
    </div>
  )
}



