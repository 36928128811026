import React from 'react';
import { Link } from 'react-router-dom';

const PartnerSection = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scroll behavior
        });
    };
    return (
        <div>
            <div className="section container">
                <div style={{ textAlign: "center" }}>
                    <h2 className="text-underline text-shadow">Join Us As Partner</h2>
                </div>
                <div className="text-center">
                    <img className="img-fluid" height={350} width={400} src='./Assets/partner_img.jpeg' alt="Partner" />
                </div>
                <div className="text-center">
                    <button className='btn btn-dark my-3' onClick={scrollToTop}>
                        <Link to="/partnerForm">Join Us Now</Link>
                        
                        </button>
                </div>
            </div>
        </div>
    );
};

export default PartnerSection;
