import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getOrders } from '../Api';
import { useSelector } from 'react-redux';
import Loader from './Loader';

export default function PreviousOrders() {
  const auth = useSelector((state) => state.auth);
  const [orders, setOrders] = useState([]);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getOrders(auth.user.id);

        if (response.success) {
          setOrders(response.data.data);
          setLoading(true);
        } else {
          console.log('Failed to fetch orders');
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, [auth]);

  return (

    <OrdersContainer className="container-fluid shadow mt-4 mb-3">
      <Title className="py-2 text-center titleWithFont">Your Orders</Title>
      {}
      {loading ? ( 
      <OrdersList>
        
        {orders.length>0 && orders.map((order, index) => (
         
            
          <OrderItem key={order.order_id} index={index}>
            <p className="order-info">Order ID: {order.order_id}</p>
            <p className="order-info">Delivery Address: {order.order_delivery_address}</p>
            <p className="order-info">Phone Number: {order.phone_number}</p>
            <p className="order-info">Order Date: {order.order_date}</p>
            <p className="order-info">Ordered Item: {order.ordered_item}</p>
            <p className="order-info">Payment Type: {order.payment_type}</p>
           
          </OrderItem>
        ))}
        {orders.length>0 ? (''):(<h6 className='text-danger text-center'>***No Orders***</h6>)}
      </OrdersList> ):(<div style={{display:'flex',justifyContent:'center'}}><Loader/></div>)}
    </OrdersContainer>
  );
}

const OrdersContainer = styled.div`
  background-color: #f8f9fa; /* Use a light gray background */
  padding: 20px;
  border-radius: 8px;
`;

const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

const OrdersList = styled.ul`
  list-style: none;
  padding: 0;
`;

const OrderItem = styled.li`
  background-color: ${(props) => (props.index % 2 === 0 ? '#f5f5f5' : '#fff')}; /* Alternating background colors */
  border: 1px solid #dee2e6; /* Add a subtle border */
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */

  .order-info {
    color: ${(props) => (props.index % 2 === 0 ? '#333' : '#555')}; /* Text color variation */
  }
`;
