import React,{useEffect, useState} from 'react'
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import {useDispatch,useSelector} from 'react-redux';
import {logout,setLocation} from '../Redux/Reducers/authSlice';
import {checkServiceAvailabilitySuccess} from '../Redux/Reducers/ServiceAvailableSlic';
import PartnerForm from './partner/partnerForm';
import styled from 'styled-components';
import { GetServiceStatus } from '../Api';

import RedeemIcon from '@mui/icons-material/Redeem';

import Logout from '@mui/icons-material/Logout';
import { Link, useNavigate } from 'react-router-dom';
export default function Navbar({ burgerStatus, setBurgerStatus }) {

  
  const [Location,setlocation] = useState('');

  const dispatch = useDispatch();
const auth = useSelector((state) => state.auth);
const handleLogout = () =>{
dispatch(logout());
} 
const findIfServiceAvailable =async (lac,long) =>{
const coord = {
  latitude:lac,
  longitude:long
}
  try{
    
    const response = await GetServiceStatus(coord);
    if(response.data.available  ){
     dispatch(checkServiceAvailabilitySuccess(true));
    }
    else{
      dispatch(checkServiceAvailabilitySuccess(false));

    }
  }
  catch(error){
    console.log(error.message);
  
  }
  }
const getGeolocation = () =>{
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        await findIfServiceAvailable( position.coords.latitude, position.coords.longitude);

        try {
          // Fetch the address using Nominatim reverse geocoding
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
          );
  
          if (!response.ok) {
            throw new Error('Failed to fetch address data.');
          }
  
          const data = await response.json();
  
          // Extract the city name from the address components
          if (data.address && data.address.city) {
            const cityName = data.address.city;
            console.log(cityName);
             setlocation(cityName);
             dispatch(setLocation(cityName));

          } else {
            console.error('City name not found in the response.');
          }
        } catch (error) {
          console.error('Error fetching address:', error.message);
        }
      },
      (error) => {
        // Handle geolocation error
        console.error('Error getting current location:', error.message);
      }
    );
  } else {
    console.error('Geolocation is not supported by this browser.');
  }
  
  
  

}


useEffect(() => {
  getGeolocation();
},[])
  
  return (
    <>
    
      <nav className="navbar navbar-expand-lg navbar-light bg-light hiddenComponents">
        <Link className="navbar-brand" to="/">LGCC HOME, Offices Maintenance Services</Link>

        <button className="navbar-toggler hiddenComponents " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <Link className="nav-link" to="/">Home <span className="sr-only"></span></Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href='#ourServices'>Services</a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href='#about'>About Us</a>
            </li>

          </ul>


          <ul className="navbar-nav mr-auto ">
          {auth.user ? (
  <>
    <li  className="nav-item ml-3"><Link to='/userProfile' className=''><span className='px-3'>Welcome</span>{auth.user.name}</Link></li>
    <li className="nav-item ml-5">
      <Link className="nav-link" title='orders' to="/previousOrders">
      <RedeemIcon/>
      </Link>
    </li>
  </>
) : (
  <>
    <li className="nav-item ml-5">
      <Link className="nav-link" title='Sign in/signup' to="/Signin">
        <PersonIcon />
      </Link>
    </li>
    
  </>
)}
           {Location !== '' ? (
  <li className='nav-item'>
    <Link className='nav-link'>{Location} <LocationOnIcon/></Link>
  </li>
) : (
  <li className="nav-item dropdown ml-5">
    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" title='Location' role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <LocationOnIcon />
    </a>
    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
      <a className="dropdown-item" onClick={getGeolocation} href="#">
        Locate Me <MyLocationIcon />
      </a>
      <Link to="/partnerForm" className="btn btn-outline bg-dark text-light mx-2" style={{display:'block',position:'fixed'}}>Join as a Partner</Link>

    </div>
  </li>
)}
            <li className='nav-item '>
      <a className='nav-link'  onClick={handleLogout}><Logout/></a>
    </li>
          </ul>
          {/* <form className="form-inline my-2 my-lg-0">
            <input className="form-control mr-sm-2" type="search" value={searchQuery} onChange={(e)=>{
              setBurgerStatus(true);
              setSearchQuery(e.target.value);
            }} placeholder="Search" aria-label="Search" />
            <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
          </form> */}
        </div>

        <Link to="/partnerForm" className="btn btn-outline bg-dark text-light mx-2" style={{display:'block',position:'fixed',right:'10px',zIndex:'1000'}}>Join as a Partner</Link>

      </nav>
     
    </>
  )
}





