import React from 'react';
import styled from 'styled-components';

export default function About() {
  return (
    <AboutUs id="about" className='container  rounded mt-5'>
      <div className='text-center  '>
        <h2 className='text-with-shadow'>About Us</h2>
      </div>
      <div className='mt-5 px-2 py-2'>
        <p>Welcome to <span className='titleWithFont text-secondary'> Liyan Gulf Contracting </span>, where innovation meets excellence!</p>
        <p>
          At Liyan Gulf Contracting, we take pride in delivering a wide range of services to meet all your needs. From cutting-edge <span className='titleWithFont' style={{color:'#BC7AF9'}}> interior design </span>to robust <span className='titleWithFont' style={{color:'#F05941'}}> electrical and mechanical solutions</span>, we've got you covered.
        </p>
        <p>
          Our commitment to <span className='titleWithFont text-danger'> safety and security </span>is paramount, ensuring peace of mind for our clients. Additionally, our<span className='titleWithFont text-warning'> IT solutions </span>bring the latest technology to streamline your operations.
        </p>
        <p>
          Liyan Gulf Contracting is not just about construction; it's about creating environments that inspire. We also provide top-notch <span className='titleWithFont' style={{color:'#205295'}}> material supply</span>, ensuring the quality and durability of every project we undertake.
        </p>
        <p>
          With our dedicated <span className='titleWithFont'style={{color:'#EE7214'}}>manpower supply </span>, we bring skilled professionals to drive the success of your projects. Trust us for excellence in every aspect of your venture.
        </p>
        <p>
          Liyan Gulf Contracting - where your vision meets our expertise, creating spaces that leave a lasting impression.
        </p>
      </div>
      {/* <marquee className="mt-5" style={{font:'italic'}}>
        <span className='' style={{color:'dark'}}>
          <span className='px-2 py-2  text-with-shadow rounded  border border-4' style={{fontSize:'1.5rem',fontWeight:'bolder',}}> < span className='px-2 text-warning text-with-shadow'>&#9733;</span> Interior Desining</span>
          <span className='px-2 py-2  text-with-shadow rounded  border border-4 ' style={{fontSize:'1.5rem',fontWeight:'bolder'}}>< span className='px-2 text-warning text-with-shadow'>&#9733;</span>  Safety and Security</span>
          <span className='px-2 py-2  text-with-shadow rounded  border border-4' style={{fontSize:'1.5rem',fontWeight:'bolder',}}> < span className='px-2 text-warning text-with-shadow'>&#9733;</span> ManPower Supply</span>

        </span>
      </marquee>
      <marquee className="mt-5"  behavior="scroll" direction="right">
        <span className='' style={{color:'dark'}}>
          <span className='px-2 py-2  text-with-shadow rounded border border-4' style={{fontSize:'1.5rem',fontWeight:'bolder',}}>< span className='px-2 text-warning text-with-shadow'>&#9733;</span> Construction & Material Supply</span>
          <span className='px-2 py-2  text-with-shadow rounded border border-4' style={{fontSize:'1.5rem',fontWeight:'bolder',}}>< span className='px-2 text-warning text-with-shadow'>&#9733;</span> ElectroMechanical</span>
          <span className='px-2 py-2  text-with-shadow rounded border border-4' style={{fontSize:'1.5rem',fontWeight:'bolder'}}> < span className='px-2 text-warning text-with-shadow '>&#9733;</span> IT Solutions</span>

        </span>
      </marquee> */}
    </AboutUs>
  );
}

const AboutUs = styled.section`
  width: 100vw;
 
`;
