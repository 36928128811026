import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './Reducers/authSlice';
import orderSlice from './Reducers/orderSlice';
import adminSlice from './Reducers/adminSlice';
import serviceAvailableSlice from './Reducers/ServiceAvailableSlic';
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: persistedReducer,
    adminauth:adminSlice,
    Orders:orderSlice,
    isServiceAvailable:serviceAvailableSlice
  },
});

export const persistor = persistStore(store);
export default store;
