import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROOT_URL, partner_Url } from '../../Utils';

const PartnerLogin = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    category: ''
  });
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log('form data', `${partner_Url}/admin/subAdminSignInAdmin`);
      const response = await fetch(`${partner_Url}/admin/subAdminSignInAdmin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        console.log('Login successful');
        localStorage.setItem('loggedInUserEmail', formData.email);

        navigate("/admin/dashboard/partnerDashboard", { state: { category: formData.category, email: formData.email } });

      } else {
        console.error('Login failed');
        const responseData = await response.json();
        setLoginError(responseData.message);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <div className='partner-login container my-4 border rounded-4 shadow bg-light'>
       <Link to='/'> Back to Home </Link>
      <center> <h3>Login Form</h3></center>
      {loginError && (
        <div className="alert alert-danger" role="alert">
          {loginError}
        </div>
      )}
      <div className='container  '>
        <form onSubmit={handleSubmit} className=''>
          <div className='row'>
            <div className='col-md-6'>
              <label className='form-label my-2'>Enter Email</label>
              <input type='email' className='form-control' id='email' name='email' placeholder='Enter your Email' onChange={handleChange} />
            </div>
            <div className='col-md-6'>
              <label className='form-label my-2'>Enter Password</label>
              <input type='password' className='form-control' id='password' name='password' placeholder='Enter your Password' onChange={handleChange} />
            </div>
            <div className='col-md-12'>
              <label className='form-label my-3'>Select Role</label>
              <select className='form-select' name='category' onChange={handleChange}>
                <option value=''>Choose role</option>
                <option value="InteriorDesign">Interior Design</option>
                <option value="ElectroMechanical">Electro-Mechanical</option>
                <option value="FireFighting">Fire Fighting</option>
                <option value="ItSolution">IT Solution</option>
                <option value="MaintenanceContract">Maintenance Contracts</option>
                <option value="SupplyMaterial">Supply Material</option>
                <option value="ManpowerSupply">Man Power Supply</option>
                <option value="LandscapperWorker">Landscapper Worker</option>
              </select>
            </div>
          </div>
          <div className='my-4'>
            <button className='btn btn-primary' type='submit'>Submit</button>
          </div>
        </form>
      </div>
      <div className='new'>
        <p>If you're new here, <Link to='/partnerform'>Sign up here</Link></p>
      </div>
    </div>
  );
}

export default PartnerLogin;
