import React,{useEffect,useState} from 'react'
import styled from 'styled-components'
import { getServices } from '../Api';
import {useNavigate} from 'react-router-dom';
import Loader from './Loader';
export default function Services() {
  const [data, setData] = useState([]);
  const [pos, setPos] = useState(0);
  const imageList = ['/Assets/img1.jpeg','/Assets/img2.jpeg','/Assets/img1.jpeg','/Assets/img3.jpeg'];

  useEffect(() => {
    const interval = setInterval(() => {
      setPos((prevPos) => (prevPos + 1) % imageList.length);
    }, 4000); // Change the interval as needed (in milliseconds)

    return () => clearInterval(interval);
  }, []);
 

  const Hero = styled.div`
  transition: background-image 2s ease-in-out, background-size 2s ease-in-out;
  background: linear-gradient(to right, rgb(0 0 0 / 0.5), rgb(0 0 0 / 0)),
    url(${(props) => props.bgImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f0f0f0;
`;


const navigate = useNavigate();
useEffect(() => {
  const callGetServices = async () => {
  try {
  const response = await getServices();
  if (response.success) {
  setData(response.data.data);
  } else {
  console.log('Error fetching services');
  }
  } catch (error) {
  console.error('An error occurred:', error);
  }
  };
  
  callGetServices();
  }, []);
  const handleOnClick = (id) =>{
    let encryptedId = window.btoa(id);
    if(id == 7){
      navigate('/manpowersupply');
    }
    else{
    navigate(`/services/${encryptedId}`)};
}
    const containerStyles = {
        width: '28vw',
        height: '40vh',
      };
      const containerStyles2 = {
        width: '40vw',
        height: '40vh',
      };
      const imageStyles = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      };
     
        const handleContactButtonClick = () => {
          const emailAddress = 'Support@liyangulfcontracting.com'; // Replace with your email address
          const mailtoUrl = `mailto:${emailAddress}`;
          window.location.href = mailtoUrl;
        };
      
  return (
    <>
   
 <Hero className=' px-4  text-center gradientForBanner  rounded  mb-2'bgImage={imageList[pos]} style={{height:'auto',fontSize:'60px'}}>
    <p className='text-with-shadow text-white py-5'>Crafting Dreams, Building Realities: Your Vision, Our Construction – At Your Doorstep.
   </p>
   <div className=' mb-5 d-flex py-2 justify-content-center'>
    <button className='rounded-75 btn btn-dark text-white'style={{fontWeight:'900',cursor:'pointer'}} onClick={handleContactButtonClick}>Contact Us</button>
   </div>
   </Hero>
   <ServiceLayout className='rounded ' id="ourServices">
    <div className='w-50 mt-2 textContainerLayout d-flex align-items-end justify-content-center'>
       <p className='py-2 ml-1 text-center'>Our Doorstep Services </p>
    </div>
    <div className='serviceLayout'>
    <OurServices className='rounded px-2 serviceBox shadow'>
   
    {data.length ? (
data.map((service, index) => (
<div className='mt-5 hoverEffect' id={service.id} key={index}  onClick={() =>{handleOnClick(service.id)}}>
<div className="card shadow  checkImageCenter" style={{ width: '8rem' }}>
  <div className='mt-3'>
<img width="48" height="48"  className=" imageFormard " src={service.image} alt="Card image cap" />
</div>
<div className="card-body">
<p className="card-text text-center titleWithFont">{service.title}</p>
</div>
</div>
</div>
))
) : (
<Loader/>
)}
   
    
    </OurServices>
    <Images className='rounded d-flex flex-column imageDivx shadow hiddenComponents'>
      <div className='d-flex  '>
    <div id="imageContainer" className='rounded shadow p-2 mb-3 ml-4 ' style={containerStyles}>
      <img
      className='rounded shadow'
        src="https://images.unsplash.com/photo-1558227691-41ea78d1f631?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Y29uc3RydWN0aW9uJTIwd29ya2Vyc3xlbnwwfHwwfHx8MA%3D%3D"
        alt="Unsplash Image"
        style={imageStyles}
      />
    </div>
    <div id="imageContainer" className='rounded shadow p-2 mb-3 ml-4 ' style={containerStyles2}>
        
      <img
      className='rounded shadow'
        src="https://images.unsplash.com/photo-1626863905121-3b0c0ed7b94c?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDB8fHNlcnZpY2VzfGVufDB8fDB8fHww"
        alt="Unsplash Image"
        style={imageStyles}
      />
    </div>
    </div>
    <div  className='d-flex '>
    <div id="imageContainer" className='rounded shadow p-2 mb-3 ml-4 ' style={containerStyles2}>
      <img
      className='rounded shadow'
      src='https://images.unsplash.com/photo-1565954786194-d22abeaac3ae?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzZ8fHdvcmtlcnxlbnwwfHwwfHx8MA%3D%3D'
        alt="Unsplash Image"
        style={imageStyles}
      />
    </div>
   
    
    <div id="imageContainer" className='rounded shadow p-2 mb-3 ml-4  ' style={containerStyles}>
      <img
      className='rounded shadow'
      src='https://images.unsplash.com/photo-1632862378103-8248dccb7e3d?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Y29uc3RydWN0aW9uJTIwd29ya2Vyc3xlbnwwfHwwfHx8MA%3D%3D'
        alt="Unsplash Image"
        style={imageStyles}
      />
    </div>
    </div>
    </Images>
    </div>
   </ServiceLayout>
   </>
  )
}


const ServiceLayout = styled.section`
width:98vw;


.serviceLayout{
display: grid;
grid-template-columns: auto auto; /* Two rows with automatic height */
grid-gap: 10px; 
}

.textContainerLayout p{
font-size:28px;
font-weight:900;
letter-spacing:2px;
text-shadow:0px 0px 30px #bb7df1;

}
.checkImageCenter {
display:flex;
flex-direction:column;
align-items:center;
}
.serviceBox{ display:grid;
  grid-template-columns: repeat(3, auto);
  align-items:center;
  justify-items:center;
  }
overflow:hidden;
@media screen and (max-width: 780px) {
  .serviceLayout{
  display:flex;
  flex-direction:column;
  overflow:scroll;
}
.serviceBox{
  width:100%
 
}
.serviceBox{
  display:grid;
  grid-template-columns: repeat(2, auto);
}
.imageDivx{
display:none !important;
}

}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .serviceLayout{
    display:flex;
    flex-direction:column;
  }
  .serviceBox{
    width:100%
   
  }
  .imageDivx{
    display:none !important;
    }
  .serviceBox{
    display:grid;
    grid-template-columns: repeat(3, auto);
  }
  }

`
const OurServices = styled.div`
width:50vw;




`
const Images = styled.div`
width:46vw;
height:86vh;

margin-top:5vh;
background: #abbaab;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #ffffff, #abbaab);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #ffffff, #abbaab); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


@media screen and (max-width:786px){
 .hiddenComponents{
  display: none !important;
 }



 .imageDivx{
  visiblity:hidden !important;
 }

}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .hiddenComponents{
    display: none !important;
   }
  }

`

