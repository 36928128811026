import React,{useState} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
export default function Footer() {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [okay,setOkay] = useState(false);

// Set the app element for react-modal
Modal.setAppElement('#root');

  const handlePdfDisplay = (pdfName) => {
    // Assuming you have PDF files stored in your project's public folder
    const pdfPath = `/Assets/pdfs/${pdfName}.pdf`;
    setModalIsOpen(true);
    setPdfUrl(pdfPath);
 
  };

  const handleContactButtonClick = () => {
    const emailAddress = 'admin@liyangulfcontracting.com'; // Replace with your email address
    const mailtoUrl = `mailto:${emailAddress}`;
    window.location.href = mailtoUrl;
  };


  const handleOkay = () =>{
    closeModal();
    }

    const closeModal = () => {
      setModalIsOpen(false);
      setPdfUrl('');
    };
  
  return (
    <>
     <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
 {pdfUrl && (
        <ResponsiveEmbedContainer >
          {/* Display the PDF */}
          <EmbedPdf  src={pdfUrl} type="application/pdf"/>
        </ResponsiveEmbedContainer>
      )}
      <hr/>
      <div className='d-flex justify-content-center'>
        <button className='btn btn-dark text-white rounded' style={{fontWeight:'bolder'}}
        onClick={()=>{handleOkay()}}>Close</button>
      </div>
      </Modal>
   
    <StyledFooter className="mt-4 rounded bg-dark text-white">
      <div className="text-center">
        <h2>Liyan Gulf Contracting Company</h2>
      </div>
      <div style={{ fontSize: '15px' }} className="d-flex  justify-content-around p-4">
          <div className='row'>
        <div className='col-md-4'>

          
          <h4>Our Services</h4>
          <ul type="none">
            <li>Building Materials</li>
            <li>Construction Materials</li>
            <li>Skilled Manpower</li>
            <li>Designing Work</li>
            <li>Supply Materials</li>
            <li>Annual Contracts</li>
          </ul>
        </div>
        <div className='col-md-4'>
          <h4>For Professionals</h4>
          <ul type="none">
          <li className='policy' onClick={() => handlePdfDisplay('policy')}>
          Privacy Policy
        </li>
        <li className='policy' onClick={() => handlePdfDisplay('Anti-DiscriminationPolicy')}>
          Anti-Discrimination Policy
        </li>
        <li className='policy' onClick={() => handlePdfDisplay('Termsandconditions')}>
          Terms and Conditions
        </li>
           
          </ul>
         
        </div>
        <div className='col-md-4'>
          <h4>Contact Us</h4>
          <p title='Get in touch' style={{cursor:'pointer'}}>Mobile No: 0096611 247 3252</p>
          <p title='Get in touch' style={{cursor:'pointer'}}>Email: support@liyangulfcontracting.com</p>
         
          <p>
            Address: Ali Bin Muhammad Bin Abdul Wahab,
            <br />
            8 6 3 8 Al Malaz Dist, RIYADH, Kingdom of Saudi Arabia.
          </p>
          <div>
          <h4>Help Support Contact</h4>
          <p onClick={() => {handleContactButtonClick()}} title='Get Support' style={{cursor:'pointer'}}>Email:admin@liyangulfcontracting.com</p>
        </div>
        </div>
        
      </div>
      </div>
      <div className="text-center px-3">
        <p>&copy; 2023 Liyan Gulf Contracting Company. All rights reserved.</p>
        {/* <p>
          Designed and Developed by{' '}
          <StyledLink href="https://syedmarketing.com/" target="_blank" rel="noopener noreferrer">
            Syed Marketing Agency
          </StyledLink>
        </p> */}
      </div>
    </StyledFooter>
    </>
  );
}

const StyledFooter = styled.footer`
  background-color: #343a40;
  color: #fff;
  padding: 20px;

  .policy{
    cursor:pointer;
  }
`;

const StyledLink = styled.a`
  color: #ffcd00;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ResponsiveEmbedContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 75%; /* 4:3 Aspect Ratio (75% = 3 / 4 * 100) */
  overflow:auto;
`;

const EmbedPdf = styled.embed`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
