

import React, { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
// import { ROOT_URL } from '../../Utils';
import axios from 'axios'
import { SignupPartner } from '../../Api';
import { partner_Url, signupUrl } from '../../Utils';
const PartnerForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '+966',
        email: '',
        password: '',
        category: ''
    });
    const navigate=useNavigate("")

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // import axios from 'axios';

const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        // console.log('process.env.REACT_APP_API_URL',process.env.REACT_APP_API_URL);
        // console.log(,signupUrl);
        // Assuming formData is defined somewhere in your code
        const response = await axios.post(`${partner_Url}/admin/subAdminSignUpAdmin`, formData);
        
        // Handle response if needed
        console.log('oooooooo',response);
        console.log('Response from server:', response.data);

        // Navigate after successful submission
        navigate("/partnerlogin");
        
        // You can reset the form state here if needed
    } catch (error) {
        console.log('An error occurred during form submission:', error);
    }
};


    return (
        <div className='partnerform container border rounded p-4 my-4 bg-light shadow'>
              <Link to='/'> Back to Home </Link>
                <center><h3>Join As Partner</h3></center>
                    <div className='container'>
                <form onSubmit={handleSubmit} className=''>
                    <div className='row'>
                    <div className="col-md-6">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input type="text" className="form-control" id="name" name="name" placeholder='Enter your Name' value={formData.name} onChange={handleChange} />
                    </div>
                    <div className=" col-md-6">
                        <label htmlFor="mobile" className="form-label">Mobile</label>
                        <input type="text" className="form-control" id="mobile" placeholder='Enter your Mobile' name="mobile" value={formData.mobile} onChange={handleChange} pattern="\+966[1-9][0-9]{8}" title="Mobile number must start with +966 and be followed by 9 digits." />
                    </div>
                    </div>
                    <div className='row'>
                    <div className="col-md-6">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="email" className="form-control" id="email" placeholder='Enter your email' name="email" value={formData.email} onChange={handleChange} />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input type="password" className="form-control" id="password" placeholder='Enter your password' name="password" value={formData.password} onChange={handleChange} />
                    </div>
                    </div>
                    <div className='row'>
                    <div className=" col-md-12">
                        <label htmlFor="category" className="form-label">Category</label>
                        <select className="form-select" id="category" name="category" value={formData.category} onChange={handleChange}>
                            <option value="">Select Category</option>
                            <option value="InteriorDesign">Interior Design</option>
                            <option value="ElectroMechanical">Electro-Mechanical</option>
                            <option value="FireFighting">Fire Fighting</option>
                            <option value="ItSolution">IT Solution</option>
                            <option value="MaintenanceContract">Maintenance Contracts</option>
                            <option value="SupplyMaterial">Supply Material</option>
                            <option value="ManpowerSupply">Man Power Supply</option>
                            <option value="LandscapperWorker">Landscapper Worker</option>
                        </select>
                    </div>
                    </div>
                    <div className='my-4'>
                    <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
              
            </div>
                <div className=''>
                    <p>Old User Login Here  <Link to='/partnerlogin'>Login here</Link> </p>
                </div>
        </div>
    );
};

export default PartnerForm;


