import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null, 
    location:'',
    phoneNumber:'',
    address:''
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    setLocation: (state,action) => {
      state.location = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPhone :(state,action) => {
      state.phoneNumber = action.payload;
    },
    setAddress : (state,action) => {
      state.address = action.payload;
    }
  },
});

export const { login, logout,setUser,setLocation,setPhone,setAddress } = authSlice.actions;
export default authSlice.reducer;