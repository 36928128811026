import React, { useEffect,useState } from 'react';
import { adminDeletOrder, adminGetOrders } from '../Api';
import { success,error } from '../Config/toastify';
import Modal from 'react-modal';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  // Sample data for orders (replace with actual data from your API or state)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [okay,setOkay] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await adminGetOrders();

        if (response.success) {
          setOrders(response.data.data);
          console.log('response.data.data',response.data.data);
          success('All Orders are Here!') // Assuming your data is in the "data" property of the response
        } else {
          error(error.message);
        }
      } catch (error) {
    
      }
    };

    fetchOrders();
  }, [okay]); // Empty dependency array ensures the effect runs only once on mount
  
const handleOkay = () =>{
setOkay(true);
closeModal();
}
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handledestroyOrder =async (orderId) => {
    try{
   setModalIsOpen(true);
   if(okay){
    const response = await adminDeletOrder(orderId);
    if(response.success){
      success('order deleted form database');
    }
    else{
      error('something went wrong try again later!')
    }
   }
    }
    catch(error){
      console.log(error.message);
    }
  }
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className='container mt-4 d-flex flex-column align-items-cennter justify-content-center'>
        <div className='rounded'>
        <h2>Are You Sure!This action deletes the order from the database.</h2>
        </div>
        <div className='d-flex  justify-content-around mt-4' >
          <div >
        <button className='btn btn-danger rounded text-white' onClick={()=>{handleOkay()}}>OKay </button>
        </div>
        <div>
        <button className='btn btn-dark rounded text-white' onClick={closeModal}>Close </button>
        </div>
        </div>
        </div>
      </Modal>
    {orders.length === 0 ? (<h1 className='mt-4 text-center' style={{fontSize:'24px',fontWeight:'400'}}>Loading</h1>): (
    <div className="container mt-4">
      <h2>Orders</h2>
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <th>OrderID</th>
            <th>Customer_id</th>
            <th>Service</th>
            <th>Mobile No</th>
            <th>Address</th>
            <th>Order Status</th>
            <th>acceptedBy</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.order_id}>
              <td>{order.order_id}</td>
              <td>{order.user_id}</td>
              <td>{order.ordered_item}</td>
              <td>{order.phone_number}</td>
              <td>{order.order_delivery_address}</td>
              <td>{order.order_status}</td>
              

              <td>{order.acceptedBy == null ? 'Not Accepted yet' : order.acceptedBy}</td>
                
              <td>
               
                <button type="button" className="btn btn-danger btn-sm" onClick={() =>{handledestroyOrder(order.order_id)}}>
                  Remove if service done
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    )}
    </>
  );
};

export default Orders;
