
import React, { useEffect, useState } from 'react';
import axios from 'axios'; 

import './partnerUsr.css';
import { ROOT_URL, partner_Url } from '../Utils';


const PartnerUser = () => {
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        const fetchPartners = async () => {
            try {
                const response = await axios.get(`${partner_Url}/admin/getAllPartners`);
                if (response.data.success) {
                    setPartners(response.data.data);
                   
                } else {
                    console.error('Failed to fetch partners');
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };

        fetchPartners();
    }, []);

    const sendEmail = async (email, status) => {
        try {
            // Sending action via GET request to the provided API endpoint
            await axios.get(`${partner_Url}/admin/action/${email}/${status}`);
            console.log('Email sent successfully');
            window.location.reload();
        } catch (error) {
            console.error('Failed to send email:', error);
        }
    };

    const deleteUser = async (email) => {
        try {
            // Sending action via DELETE request to the provided API endpoint
            await axios.delete(`${partner_Url}/admin/action/${email}`);
            window.location.reload();
        } catch (error) {
            console.error('Failed to delete user:', error);
        }
    };

    return (
        <div className='container'>
            <center><h2>All Partners</h2></center>
          
            <div className='container'>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Email</th>
                            <th>Category</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {partners
                            // Filter out partners with role equal to 0
                            .filter(partner => partner.role !== 0)
                            .map(partner => (
                                <tr key={partner.id}>
                                    <td>{partner.id}</td>
                                    <td>{partner.name}</td>
                                    <td>{partner.mobile}</td>
                                    <td>{partner.email}</td>
                                    <td>{partner.category}</td>
                                    <td>
                                        {partner.approved === "false" ? (
                                            <button type="button" className='btn btn-primary' onClick={() => sendEmail(partner.email, 'false')}>for Approve</button>
                                        ) : (
                                            <button type="button" className='btn btn-danger' onClick={() => sendEmail(partner.email, 'true')}>for Reject</button>
                                        )}
                                        <button type="button" className='btn btn-danger mx-2' onClick={() =>  deleteUser(partner.email)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PartnerUser;
