import React, { useState ,useEffect} from 'react';
import styled from 'styled-components';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { success, error } from '../Config/toastify';
import Loader from './Loader';
import { setAddress,setPhone } from '../Redux/Reducers/authSlice';
import PaymentComponent from '../Payments/PaymentComponent';

export default function Booking() {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [delivery, setDelivery] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isDetails,setDetails] = useState(false);
  const Orders = useSelector((state) => state.Orders.orders);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        // Simulate an asynchronous operation (e.g., fetching data) during component mount
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    };

    fetchData();
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
   
  }, []);



  if (loading) {
    return (<LoadingMessage> <Loader/></LoadingMessage> )
  }
  const handleConfirmDetails = ()=>{
    setDetails(true);
  }
 
  return (
    <BookContainer className="container d-flex Orders flex-column mt-4 justify-content-between shadow">
      <div>
        <h6 className="text-center titleWithFont">Your Order</h6>
        <OrderDetailsContainer className="px-2 py-2 shadow rounded">
       {Orders ? (  <p className="py-2 text-center">{Orders.order.title}</p>):(<p className='text-center text-capitalize text-danger'>***Go Back and please select your service*** </p>)} 
          <div className="text-center">
            <NoteText className="text-primary text-capitalize text-center px-2 py-2 text-italic">
              NOTE: Supply charges as per client request of material and quantity. We will share an official quotation.
            </NoteText>
          </div>
        </OrderDetailsContainer>
      </div>
      <AddressContainer className="px-2 py-3 shadow">
        <InputLabel htmlFor="address">Enter your address</InputLabel>
        <TextArea id="address" name="address" value={delivery} onChange={(e) => {
          
          setDelivery(e.target.value)}} />

        <hr />

        <InputLabel htmlFor="phoneNumber">Enter Phone Number</InputLabel>
        <InputText type="text" value={phoneNumber} onChange={(e) =>{ 
          
          setPhoneNumber(e.target.value)}} id="phoneNumber" name="phoneNumber" placeholder="Phone Number" />
      </AddressContainer>
 {isDetails ? (
      <PaymentContainer className="shadow container d-flex flex-wrap payment justify-content-between mb-4 align-items-center rounded mt-4">
        <div>
          <PaymentDetailsText>
            Net Payment:<span>SAR 55</span> <span className="text-danger">***This payment is non-refundable***</span>
          </PaymentDetailsText>
        </div>
        <div>
          <form>
            <div className="form-group">
              <label htmlFor="paymentMethod">Select Payment Method</label>
              <select className="form-control" id="paymentMethod" value="online">
                <option value="online">Online Payment</option>
              </select>
            </div>
          </form>
        </div>
       
         <div className='mt-5'>
          
          
         <PaymentComponent Orders={Orders} delivery={delivery} phoneNumber={phoneNumber} />
        
       </div>
      
       
      
      </PaymentContainer>
      ):(
        <>
      <div className='w-100 d-flex justify-content-center mt-5 mb-3'>
            <button className='btn btn-dark text-white rounded' onClick={()=>handleConfirmDetails()}>Confirm Details</button>
      </div>
      </>
       )}
    </BookContainer>
  );
}

const BookContainer = styled.section`
  overflow: hidden;
`;

const OrderDetailsContainer = styled.div`
  background-color: #fff; /* Add a white background to order details */
`;

const NoteText = styled.span`
  color: #007bff; /* Use a blue color for the note text */
`;
const LoadingMessage = styled.p`
width:95vw;
height:100vh;
 display:flex;
 justify-content:center;
 align-items:center;
`;
const AddressContainer = styled.div`
  background-color: #f8f9fa; /* Use a light gray background for address section */
  margin-top: 20px;
  padding: 15px;
  border-radius: 8px;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 15px;
`;

const InputText = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
`;

const PaymentContainer = styled.div`
  background-color: #d6d8db; /* Use a light gray background for payment details section */
  padding: 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaymentDetailsText = styled.p`
  margin: 0;
  span {
    margin-left: 5px;
    font-weight: bold;
  }
`;


