import { useState,useEffect } from "react";
import { resetByRequestForPassword } from "../Api";
import { error, success } from "../Config/toastify";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../Config/validateEmail";
export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Password Reset page';
  }, []);
  const handleEmailToReset = async (e) => {
  e.preventDefault();
  if(!validateEmail(email))
  {
   error("InValid Email address");
   return;
  }
    const userBody = {
      email:email
    }
   const response = await resetByRequestForPassword(userBody);
   if(response.success){
   success('check your mailBox for reset link');
   navigate('/signin');
   }
   else{
    error('Something went wrong please check your emailId')
   }
   
  }
  return (
    <div className="container mt-5">
      <form className="card p-4 mx-auto"  onSubmit={handleEmailToReset}>
        <div className="mb-3">
          <label className="form-label" htmlFor="username">
            Email
          </label>
          <input
            className="form-control"
            id="username"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="d-grid">
          <button
            className="btn btn-primary"
            type="submit"
       
          >
            Send Link
          </button>
        </div>
      </form>
    </div>
  );
}
